var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GlPageWrap',{staticClass:"entity-page__wrapper",attrs:{"hide-title-on-mobile":"","title":_vm.getFirstRouteName(_vm.$route) === 'entity-management'
      ? 'Entity Management'
      : 'Entity Explorer'},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.$route.name === 'view-entity-explorer' && _vm.isAdmin)?_c('GlButton',{staticClass:"min-w-250 m-fullwidth",style:([{ height: '38px' }]),attrs:{"pale-dark":"","title":"Manage"},on:{"click":function($event){return _vm.$router.push({
          name: 'view-entity-management',
          params: { entityId: _vm.$route.params.entityId },
        })}}}):_vm._e(),(_vm.getFirstRouteName(_vm.$route) === 'entity-management')?_c('div',{staticClass:"flex m-display-block gap-4 m-fullwidth entity-page__buttons-wrap"},[(_vm.$route.name === 'view-entity-management')?_c('GlButton',{staticClass:"min-w-250 m-fullwidth",style:([{ height: '38px' }]),attrs:{"pale-light":"","title":"Edit Entity"},on:{"click":function($event){return _vm.$router.push({
            name: 'edit-entity-management',
            params: { entityId: _vm.$route.params.entityId },
          })}}}):_vm._e(),(_vm.$route.name !== 'create-entity-management')?_c('GlButton',{staticClass:"min-w-250 m-fullwidth",style:([{ height: '38px' }]),attrs:{"pale-dark":"","title":"Create New Entity"},on:{"click":function($event){return _vm.$router.push({ name: 'create-entity-management' })}}}):_vm._e(),(
          _vm.$route.name === 'create-entity-management' ||
            _vm.$route.name === 'edit-entity-management'
        )?_c('GlButton',{staticClass:"min-w-250 m-fullwidth",style:([{ height: '38px' }]),attrs:{"pale-dark":"","title":"Publish"},on:{"click":function($event){return _vm.openConfirmModal()}}}):_vm._e()],1):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"flex t-display-block gap-4 entity__wrapper"},[_c('EntityListTable',{staticClass:"entity-list-table__wrapper",attrs:{"tags-list":_vm.tagsList,"types-list":_vm.typesList}}),_c('router-view',{ref:"entityFormWrapper",staticClass:"entity-view-wrapper",attrs:{"countries-list":_vm.countriesList,"languages-list":_vm.languagesList,"tags-list":_vm.tagsList,"types-list":_vm.typesList},on:{"closeConfirmPublishModal":function($event){_vm.showConfirmPublishModal = false},"loadingModal":_vm.loadingModalToggle,"setImageModalData":_vm.setImageModalData,"showViewImageModal":function($event){_vm.showViewImageModal = true}}}),(
        _vm.$route.name === 'entity-management' ||
          _vm.$route.name === 'entity-explorer'
      )?_c('div',{staticClass:"entity-view-wrapper entity-empty__wrapper"},[_vm._v(" Select entity ")]):_vm._e()],1),(_vm.showConfirmPublishModal)?_c('GlConfirmModal',{attrs:{"loading":_vm.loadingModal,"title":"Publish"},on:{"close":function($event){_vm.showConfirmPublishModal = false},"confirm":function($event){return _vm.publishConfirmed()}},scopedSlots:_vm._u([{key:"main-slot",fn:function(){return [_vm._v(" "+_vm._s(_vm.$route.name === 'create-entity-management' ? 'Do you confirm adding a new entity?' : 'Do you confirm edit entity?')+" ")]},proxy:true}],null,false,3688820402),model:{value:(_vm.showConfirmPublishModal),callback:function ($$v) {_vm.showConfirmPublishModal=$$v},expression:"showConfirmPublishModal"}}):_vm._e(),(_vm.showViewImageModal)?_c('ViewImageModal',{attrs:{"active-image-index":_vm.activeImageIndex,"data":_vm.imageList,"ok-only":""},on:{"confirm":_vm.confirmImageModal,"setActiveImageIndex":_vm.setActiveImageIndex},model:{value:(_vm.showViewImageModal),callback:function ($$v) {_vm.showViewImageModal=$$v},expression:"showViewImageModal"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }