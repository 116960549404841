<template>
  <GlPageWrap
    class="entity-page__wrapper"
    hide-title-on-mobile
    :title="
      getFirstRouteName($route) === 'entity-management'
        ? 'Entity Management'
        : 'Entity Explorer'
    "
  >
    <template #actions>
      <GlButton
        v-if="$route.name === 'view-entity-explorer' && isAdmin"
        class="min-w-250 m-fullwidth"
        pale-dark
        :style="[{ height: '38px' }]"
        title="Manage"
        @click="
          $router.push({
            name: 'view-entity-management',
            params: { entityId: $route.params.entityId },
          })
        "
      />
      <div
        v-if="getFirstRouteName($route) === 'entity-management'"
        class="flex m-display-block gap-4 m-fullwidth entity-page__buttons-wrap"
      >
        <GlButton
          v-if="$route.name === 'view-entity-management'"
          class="min-w-250 m-fullwidth"
          pale-light
          :style="[{ height: '38px' }]"
          title="Edit Entity"
          @click="
            $router.push({
              name: 'edit-entity-management',
              params: { entityId: $route.params.entityId },
            })
          "
        />
        <GlButton
          v-if="$route.name !== 'create-entity-management'"
          class="min-w-250 m-fullwidth"
          pale-dark
          :style="[{ height: '38px' }]"
          title="Create New Entity"
          @click="$router.push({ name: 'create-entity-management' })"
        />

        <GlButton
          v-if="
            $route.name === 'create-entity-management' ||
              $route.name === 'edit-entity-management'
          "
          class="min-w-250 m-fullwidth"
          pale-dark
          :style="[{ height: '38px' }]"
          title="Publish"
          @click="openConfirmModal()"
        />
      </div>
    </template>
    <div class="flex t-display-block gap-4 entity__wrapper">
      <EntityListTable
        class="entity-list-table__wrapper"
        :tags-list="tagsList"
        :types-list="typesList"
      />
      <router-view
        ref="entityFormWrapper"
        class="entity-view-wrapper"
        :countries-list="countriesList"
        :languages-list="languagesList"
        :tags-list="tagsList"
        :types-list="typesList"
        @closeConfirmPublishModal="showConfirmPublishModal = false"
        @loadingModal="loadingModalToggle"
        @setImageModalData="setImageModalData"
        @showViewImageModal="showViewImageModal = true"
      />
      <div
        v-if="
          $route.name === 'entity-management' ||
            $route.name === 'entity-explorer'
        "
        class="entity-view-wrapper entity-empty__wrapper"
      >
        Select entity
      </div>
    </div>
    <GlConfirmModal
      v-if="showConfirmPublishModal"
      v-model="showConfirmPublishModal"
      :loading="loadingModal"
      title="Publish"
      @close="showConfirmPublishModal = false"
      @confirm="publishConfirmed()"
    >
      <template #main-slot>
        {{
          $route.name === 'create-entity-management'
            ? 'Do you confirm adding a new entity?'
            : 'Do you confirm edit entity?'
        }}
      </template>
    </GlConfirmModal>
    <ViewImageModal
      v-if="showViewImageModal"
      v-model="showViewImageModal"
      :active-image-index="activeImageIndex"
      :data="imageList"
      ok-only
      @confirm="confirmImageModal"
      @setActiveImageIndex="setActiveImageIndex"
    />
  </GlPageWrap>
</template>

<script>
//Components
import GlPageWrap from '@/components/layout/gl-page-wrap'
import EntityListTable from '@/pages/entity/components/EntityListTable'
import GlButton from '@/components/gl-button'
import GlConfirmModal from '@/components/gl-confirm-modal'
import ViewImageModal from '@/pages/entity/modals/ViewImageModal'
// Utils
import { getFirstRouteName } from '@/utils/get-route-name'
import { isDefaultType } from '@/utils/find-default-tags-types'
// models
import {
  localCountriesList,
  allCountriesListV2,
  allCountriesListV3,
} from '@/pages/entity/models/countries-list'
// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    GlPageWrap,
    EntityListTable,
    GlButton,
    GlConfirmModal,
    ViewImageModal,
  },
  data() {
    return {
      showConfirmPublishModal: false,
      showViewImageModal: false,
      languagesList: [],
      countriesList: [],
      typesList: [],
      tagsList: [],
      imageList: [],
      activeImageIndex: 0,
      loadingModal: false,
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
    localCountriesList() {
      return localCountriesList
    },
  },
  async created() {
    allCountriesListV2.push(...localCountriesList)
    this.countriesList = allCountriesListV2
    this.languagesList = allCountriesListV3

    this.getTypes({ count: 10000 }).then(
      ({ data: { typeData } }) =>
        (this.typesList = typeData
          .filter(type => !isDefaultType(type.name))
          .map(type => {
            return { ...type }
          })),
    )
    this.getTags({ count: 10000 }).then(
      ({ data: { tagData } }) =>
        (this.tagsList = tagData.map(tag => ({
          ...tag,
          tagId: tag._id,
        }))),
    )
  },

  methods: {
    ...mapActions('entity', ['getCountries', 'getLanguages']),
    ...mapActions('tagging', ['getTags']),
    ...mapActions('types', ['getTypes']),
    getFirstRouteName,
    isDefaultType,
    publishConfirmed() {
      this.$refs.entityFormWrapper.onSubmit(
        this.$refs.entityFormWrapper.$refs.entityForm.errors,
      )
    },
    loadingModalToggle(e) {
      this.loadingModal = e
    },
    setImageModalData(data, i) {
      this.imageList = data
      this.activeImageIndex = i
    },
    setActiveImageIndex(i) {
      this.activeImageIndex = i
    },
    confirmImageModal() {
      this.showViewImageModal = false
      this.imageList = []
      this.activeImageIndex = 0
    },
    async openConfirmModal() {
      const isValid =
        await this.$refs.entityFormWrapper.$refs.entityForm.validate()
      if (isValid) {
        this.showConfirmPublishModal = true
      } else {
        this.$refs.entityFormWrapper.currentStep = 0
      }
    },
  },
}
</script>

<style>
.entity-list-table__wrapper {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 400px;
  min-width: 400px;
  padding-bottom: 0;
}

.entity-page__wrapper .o-table__th {
  font-size: 12px;
}
.entity-page__wrapper .o-table__td {
  font-size: 12px;
}
.entity-page__wrapper .entity-list-table__wrapper .o-table__th {
  padding: 4px 8px 12px 8px !important;
  font-size: 12px;
  border-color: var(--cotton-grey-f-5);
}
.entity-page__wrapper .entity-list-table__wrapper .o-table__td {
  padding: 9px 8px 10px 8px !important;
  border-bottom: 1px solid var(--cotton-grey-f-5);
  font-size: 12px;
}
.entity-page__wrapper .entity-list-table__wrapper .o-table .o-table__th:first-child,
.entity-page__wrapper .entity-list-table__wrapper .o-table .o-table__td:first-child {
  max-width: 196px;
  padding-left: 16px !important;
}
.entity-page__wrapper .entity-list-table__wrapper .o-table .o-table__td:first-child {
  width: 202px;
}
.entity-page__wrapper .entity-list-table__wrapper .o-table .o-table__td:first-child + .o-table__td {
  max-width: 100px;
}
.entity-page__wrapper .entity-list-table__wrapper .o-table .o-table__td button {
  max-height: 20px;
}

/* regulatory-compliance-table */
.entity-page__wrapper .regulatory-compliance-table .table__overflow-auto .o-table__wrapper {
  min-height: auto;
}
.entity-page__wrapper .regulatory-compliance-table .o-table__pagination {
  display: none;
}
.entity-page__wrapper .regulatory-compliance-table .o-table tr {
  display: flex;
}
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__th {
  padding: 9px 24px 12px 0 !important;
}
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__td {
  padding: 13px 24px 12px 0 !important;
  line-height: 18.7px;
}
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__th:nth-child(1),
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__td:nth-child(1) {
  width: 148px !important;
  max-width: 148px !important;
  min-width: 148px !important;
  padding-left: 24px !important;
}
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__th:nth-child(2),
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__td:nth-child(2) {
  width: 291px !important;
  max-width: 291px !important;
  min-width: 291px !important;
}
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__th:nth-child(3),
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__td:nth-child(3) {
  width: 174px !important;
  max-width: 174px !important;
  min-width: 174px !important;
}
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__th:nth-child(4),
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__td:nth-child(4) {
  width: 224px !important;
  max-width: 224px !important;
  min-width: 224px !important;
}
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__th:nth-child(5),
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__td:nth-child(5) {
  width: 308px !important;
  max-width: 308px !important;
  min-width: 308px !important;
}
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__th:nth-child(6),
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__td:nth-child(6) {
  flex: 1 0 auto;
  width: 80px !important;
  min-width: 80px !important;
  padding-right: 0 !important;
}
.entity-page__wrapper .regulatory-compliance-table--form {
  padding-top: 12px;
}
.entity-page__wrapper .regulatory-compliance-table--form .o-table .o-table__th {
  padding: 12px 24px 12px 0 !important;
}
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__td {
  padding: 11px 24px 10px 0 !important;
}

/* banks-table */
.entity-page__wrapper .banks-table .table__overflow-auto .o-table__wrapper {
  min-height: auto;
}
.entity-page__wrapper .banks-table .o-table__pagination {
  display: none;
}
.entity-page__wrapper .banks-table .o-table tr {
  display: flex;
}
.entity-page__wrapper .banks-table .o-table .o-table__th {
  padding: 11px 24px 12px 0 !important;
}
.entity-page__wrapper .banks-table .o-table .o-table__td {
  padding: 17px 24px 14px 0 !important;
  line-height: 18px;
}
.entity-page__wrapper .banks-table .o-table .o-table__th:nth-child(1),
.entity-page__wrapper .banks-table .o-table .o-table__td:nth-child(1) {
  width: 182px !important;
  max-width: 182px !important;
  min-width: 182px !important;
  padding-left: 24px !important;
}
.entity-page__wrapper .banks-table .o-table .o-table__th:nth-child(2),
.entity-page__wrapper .banks-table .o-table .o-table__td:nth-child(2) {
  width: 269px !important;
  max-width: 269px !important;
  min-width: 269px !important;
}
.entity-page__wrapper .banks-table .o-table .o-table__th:nth-child(3),
.entity-page__wrapper .banks-table .o-table .o-table__td:nth-child(3) {
  width: 247px !important;
  max-width: 247px !important;
  min-width: 247px !important;
}
.entity-page__wrapper .banks-table .o-table .o-table__th:nth-child(4),
.entity-page__wrapper .banks-table .o-table .o-table__td:nth-child(4) {
  width: 201px !important;
  max-width: 201px !important;
  min-width: 201px !important;
}
.entity-page__wrapper .banks-table .o-table .o-table__th:nth-child(5),
.entity-page__wrapper .banks-table .o-table .o-table__td:nth-child(5) {
  width: 137px !important;
  max-width: 137px !important;
  min-width: 137px !important;
}
.entity-page__wrapper .banks-table .o-table .o-table__th:nth-child(6),
.entity-page__wrapper .banks-table .o-table .o-table__td:nth-child(6) {
  flex: 1 0 auto;
  width: 163px !important;
  min-width: 163px !important;
  padding-right: 0 !important;
}
.entity-page__wrapper .banks-table--form {
  padding-top: 12px;
}


/* payments-systems-table */
.entity-page__wrapper .payments-systems-table .table__overflow-auto .o-table__wrapper {
  min-height: auto;
}
.entity-page__wrapper .payments-systems-table .o-table__pagination {
  display: none;
}
.entity-page__wrapper .payments-systems-table .o-table tr {
  display: flex;
}
.entity-page__wrapper .payments-systems-table .o-table .o-table__th {
  padding: 11px 24px 12px 0 !important;
}
.entity-page__wrapper .payments-systems-table .o-table .o-table__td {
  padding: 17px 24px 12px 0 !important;
  line-height: 18px;
}
.entity-page__wrapper .payments-systems-table .o-table .o-table__th:nth-child(1),
.entity-page__wrapper .payments-systems-table .o-table .o-table__td:nth-child(1) {
  width: 166px !important;
  max-width: 166px !important;
  min-width: 166px !important;
  padding-left: 24px !important;
}
.entity-page__wrapper .payments-systems-table .o-table .o-table__th:nth-child(2),
.entity-page__wrapper .payments-systems-table .o-table .o-table__td:nth-child(2) {
  width: 163px !important;
  max-width: 163px !important;
  min-width: 163px !important;
}
.entity-page__wrapper .payments-systems-table .o-table .o-table__th:nth-child(3),
.entity-page__wrapper .payments-systems-table .o-table .o-table__td:nth-child(3) {
  width: 533px !important;
  max-width: 533px !important;
  min-width: 533px !important;
}
.entity-page__wrapper .payments-systems-table .o-table .o-table__th:nth-child(4),
.entity-page__wrapper .payments-systems-table .o-table .o-table__td:nth-child(4) {
  width: 190px !important;
  max-width: 190px !important;
  min-width: 190px !important;
}
.entity-page__wrapper .payments-systems-table .o-table .o-table__th:nth-child(5),
.entity-page__wrapper .payments-systems-table .o-table .o-table__td:nth-child(5) {
  flex: 1 0 auto;
  width: 160px !important;
  min-width: 160px !important;
  padding-right: 0 !important;
}
.entity-page__wrapper .payments-systems-table .o-table .o-table__td:first-child {
  padding-top: 13px !important;
}
.entity-page__wrapper .payments-systems-table--form {
  padding-top: 12px;
}

/* contacts-people-table */
.entity-page__wrapper .contacts-people-table .table__overflow-auto .o-table__wrapper {
  min-height: auto;
}
.entity-page__wrapper .contacts-people-table .o-table__pagination {
  display: none;
}
.entity-page__wrapper .contacts-people-table .o-table tr {
  display: flex;
}
.entity-page__wrapper .contacts-people-table .o-table .o-table__th {
  padding: 12px 24px 12px 0 !important;
}
.entity-page__wrapper .contacts-people-table .o-table .o-table__td {
  padding: 17px 24px 12px 0 !important;
  line-height: 18px;
}
.entity-page__wrapper .contacts-people-table .o-table .o-table__th:nth-child(1),
.entity-page__wrapper .contacts-people-table .o-table .o-table__td:nth-child(1) {
  width: 371px !important;
  max-width: 371px !important;
  min-width: 371px !important;
  padding-left: 24px !important;
}
.entity-page__wrapper .contacts-people-table .o-table .o-table__th:nth-child(2),
.entity-page__wrapper .contacts-people-table .o-table .o-table__td:nth-child(2) {
  width: 407px !important;
  max-width: 407px !important;
  min-width: 407px !important;
}
.entity-page__wrapper .contacts-people-table .o-table .o-table__th:nth-child(3),
.entity-page__wrapper .contacts-people-table .o-table .o-table__td:nth-child(3) {
  flex: 1 0 auto;
  width: 458px !important;
  min-width: 458px !important;
}
.entity-page__wrapper .contacts-people-table .o-table .o-table__td:last-child {
  padding-top: 13px !important;
}
.entity-page__wrapper .contacts-people-table--form .o-table .o-table__td {
  padding: 18px 24px 13px 0 !important;
}

/* contacts-departments-table */
.entity-page__wrapper .contacts-departments-table .table__overflow-auto .o-table__wrapper {
  min-height: auto;
}
.entity-page__wrapper .contacts-departments-table .o-table__pagination {
  display: none;
}
.entity-page__wrapper .contacts-departments-table .o-table tr {
  display: flex;
}
.entity-page__wrapper .contacts-departments-table .o-table .o-table__th {
  padding: 11px 24px 12px 0 !important;
}
.entity-page__wrapper .contacts-departments-table .o-table .o-table__td {
  padding: 17px 24px 12px 0 !important;
  line-height: 18px;
}
.entity-page__wrapper .contacts-departments-table .o-table .o-table__th:nth-child(1),
.entity-page__wrapper .contacts-departments-table .o-table .o-table__td:nth-child(1) {
  width: 778px !important;
  max-width: 778px !important;
  min-width: 778px !important;
  padding-left: 24px !important;
}
.entity-page__wrapper .contacts-departments-table .o-table .o-table__th:nth-child(2),
.entity-page__wrapper .contacts-departments-table .o-table .o-table__td:nth-child(2) {
  flex: 1 0 auto;
  width: 458px !important;
  min-width: 458px !important;
}
.entity-page__wrapper .contacts-departments-table .o-table .o-table__td:last-child {
  padding-top: 13px !important;
}
.entity-page__wrapper .contacts-departments-table--form .o-table .o-table__td {
  padding: 18px 24px 13px 0 !important;
}

/* tables images */
.entity-page__wrapper .regulatory-compliance-table .o-table .o-table__td img.flag-icon-small,
.entity-page__wrapper .banks-table .o-table .o-table__td img.flag-icon-small,
.entity-page__wrapper .contacts-people-table .o-table .o-table__td img.flag-icon-small,
.entity-page__wrapper .payments-systems-table .o-table .o-table__td img.flag-icon-small,
.entity-page__wrapper .contacts-departments-table .o-table .o-table__td img.flag-icon-small {
  width: 23px !important;
  margin-right: 8px !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}
.entity-page__wrapper .contacts-people-table .o-table .o-table__td img,
.entity-page__wrapper .contacts-people-table .o-table .o-table__td svg,
.entity-page__wrapper .payments-systems-table .o-table .o-table__td img,
.entity-page__wrapper .payments-systems-table .o-table .o-table__td svg,
.entity-page__wrapper .contacts-departments-table .o-table .o-table__td img,
.entity-page__wrapper .contacts-departments-table .o-table .o-table__td svg {
  display: block;
  width: 24px;
  max-height: 24px;
  margin-top: 0 !important;
  margin-right: 0 !important;
}
.entity-page__wrapper .contacts-departments-table:not(.contacts-departments-table--form) .o-table .o-table__td svg {
  padding-top: 5px;
}
.contacts-people-departments-wrap > .col-6:first-child {
  min-width: 50%;
}

.entity-select__pagination .vs__dropdown-menu {
  font-size: 14px;
}
.entity-select__pagination {
  width: 100px;
}
.entity-select__pagination .vs__dropdown-menu {
  min-width: 100px;
}
.entity-select__pagination .vs__dropdown-toggle {
  min-height: 32px;
}

.entity__wrapper {
  min-height: var(--page-content-height);
  max-height: var(--page-content-height);
}

.entity-empty__wrapper {
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.entity-view-wrapper {
  width: calc(100% - 420px);
}

.entity-screensot {
  width: 87px;
  max-width: 87px;
  height: 50px;
  border-radius: 3px;
  position: relative;
  margin-bottom: 8px;
  overflow: hidden;
  cursor: pointer;
}

.entity-screensot img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.entity-screensot embed {
  width: 250px;
}

.entity-screensot-wrapper {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  min-width: 0;
  margin-bottom: 16px;
}

.entity-screensot-full-view-icon {
  position: absolute;
  cursor: pointer;
  top: 2px;
  left: 2px;
}

.entity-tabs-table .o-table__pagination,
.entity-tabs-table .o-table__th__sort-icon {
  margin-right: 4px;
}

@media (max-width: 1200px) {
  .entity__wrapper {
    min-height: auto;
    max-height: max-content;
  }
  .entity-list-table__wrapper {
    width: 100%;
    max-width: 100%;
    min-width: auto;
    margin-bottom: 16px;
  }
  .entity-view-wrapper {
    width: 100%;
  }
  .entity-empty__wrapper {
    padding: 32px;
  }
}
@media (max-width: 767px) {
  .entity-page__buttons-wrap button + button {
    margin-top: 16px;
  }
}
</style>
