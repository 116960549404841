<template>
  <div class="entity-table-wrapper">
    <div class="flex align-center pa-3 pb-2 relative">
      <gl-search-box
        v-model="searchField"
        class="fullwidth mr-2"
        dark-clear
        :disabled="!searchField"
        grey
        height="32px"
        independent
        :loading="loading"
        placeholder="Find in database"
        @search="loadData"
      />
      <div
        class="mr-1 ml-1 pointer relative entity-table__filter-icon"
        :class="{ 'filter-filled-sign': showFilerSign }"
        @click="toggleFilter"
      >
        <gl-icon
          :height="24"
          name="filter"
          :width="24"
        />
      </div>
      <ValidationObserver
        ref="entityListFilter"
        slim
      >
        <div
          v-show="showFilter"
          class="entity-list-filter-wrapper"
        >
          <div class="flex space-between pa-3">
            <div class="bold-600">
              Filters
            </div>
            <div
              class="flex align-center pointer soft-blue-text bold"
              @click="clearAllFilters"
            >
              <gl-icon
                class="mr-2"
                :height="24"
                name="filter"
                :width="24"
              />Clear all
            </div>
          </div>
          <div class="grey-f-5-hr" />

          <div class="pa-3">
            <div class="bold fs-14 mb-3">
              Type
            </div>
            <GlSelect
              class="mb-4 tagged mr-1"
              :clearable="true"
              label-select="name"
              :multiple="true"
              :options="filters.type.items"
              placeholder="Choose types"
              searchable
              :value="filters.type.value"
              @change="e => (filters.type.value = e)"
            />
            <div class="bold fs-14 mb-3">
              Tag
            </div>
            <GlSelect
              class="mb-4 tagged mr-1"
              :clearable="true"
              label-select="name"
              :multiple="true"
              :options="filters.tags.items"
              placeholder="Choose tags"
              searchable
              :value="filters.tags.value"
              @change="e => (filters.tags.value = e)"
            >
              <template #selected-slot="{ data }">
                {{ data.name }}
              </template>
            </GlSelect>
            <div class="bold fs-14 mb-3">
              Score
            </div>
            <div class="flex gap-4">
              <gl-input
                v-model="filters.score.from"
                class="flex-1"
                :height="40"
                label="From"
                :max="filters.score.to || 100"
                name="from"
                placeholder="From"
                type="number"
              />
              <gl-input
                v-model="filters.score.to"
                class="flex-1"
                :height="40"
                label="To"
                :max="100"
                :min="filters.score.from || 0"
                name="to"
                placeholder="To"
                type="number"
              />
            </div>
          </div>

          <div class="grey-f-5-hr" />
          <div class="flex flex-end pa-3">
            <div
              class="pointer soft-blue-text bold"
              @click="applyFilter"
            >
              Apply
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>

    <div class="table-wrap table-wrap__unoverflow gl-table-unshadow entity-table__to-bottom">
      <o-table
        backend-sorting
        class="entity-table table__overflow-auto"
        :class="{
          'o-table-has-pagination': entityDataList.length && totalPages > 1,
        }"
        :data="entityDataList"
        focusable
        hoverable
        :loading="loading"
        :mobile-cards="false"
        :selected.sync="selected"
        @select="updateSelected"
      >
        <o-table-column
          v-slot="props"
          field="entityName"
          label="Entity Name"
        >
          <div class="min-w-150 max-w-150 ellipsis">
            {{ props.row.name.value }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="type"
          label="Type"
        >
          <div
            v-if="
              props.row.data && props.row.data.type && props.row.data.type.value
            "
          >
            <GlTag
              :max-width-text="showDeleteInTable ? '105px' : '140px'"
              :score="props.row.data.type.value.score"
              :tag="props.row.data.type.value.name"
            />
          </div>
          <div v-else>
            --
          </div>
        </o-table-column>
        <o-table-column
          v-if="showDeleteInTable"
          v-slot="props"
        >
          <gl-menu-item
            v-tooltip.top="'Delete'"
            class=""
            fullwidth
            icon="delete"
            :icon-height="24"
            :icon-width="24"
            label=""
            warn
            @click.prevent.stop="clickDelete(props.row)"
          />
        </o-table-column>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="pp-pagination-table">
        <div class="pp-pagination-table__left">
          <div class="pp-pagination-table__text">
            Rows per page
          </div>
          <div class="pp-pagination-table__select">
            <vSelect
              v-model="perPage"
              class="select--top-direction"
              :clearable="false"
              :options="pagesOptions"
              :searchable="false"
              @input="countChange"
            />
          </div>
        </div>
        <div class="pp-pagination-table__right">
          <o-pagination
            v-if="entityDataList.length && totalPages > 1"
            :current.sync="currentPage"
            :per-page="perPage"
            :range-after="0"
            :range-before="0"
            :total="total"
            @change="pageChange"
          >
            <template #default="props">
              <div v-if="props.page.isCurrent" class="pp-pagination-table__pages">
                {{ `${props.page.number} of ${totalPages}` }}
              </div>
            </template>
            <template #previous="props">
              <div class="pp-pagination-table__button-prev">
                <o-pagination-button
                  :page="props.page"
                >
                  <gl-menu-item
                    :disabled="props.page.disabled"
                    icon="left"
                    :icon-height="24"
                    :icon-width="24"
                    label=""
                    not-outline
                  />
                </o-pagination-button>
                <div class="pp-pagination-table__current">{{ props.page.number + 1 }}</div>
              </div>
            </template>
            <template #next="props">
              <div class="pp-pagination-table__button-next">
                <o-pagination-button
                  :page="props.page"
                >
                  <gl-menu-item
                    :disabled="props.page.disabled"
                    icon="right"
                    :icon-height="24"
                    :icon-width="24"
                    label=""
                    not-outline
                  />
                </o-pagination-button>
              </div>
            </template>
          </o-pagination>
        </div>
      </div>
    </div>
    <GlConfirmModal
      v-if="showConfirmDeleteModal"
      v-model="showConfirmDeleteModal"
      :loading="loadingDeleteModal"
      title="Are you sure?"
      @close="showConfirmDeleteModal = false"
      @confirm="deleteEntityById(selectedDelete)"
    >
      <template #main-slot>
        Delete <b>{{ selectedDelete.name.value }}</b>?
      </template>
    </GlConfirmModal>
  </div>
</template>

<script>
// Components
import vSelect from 'vue-select'
import GlTag from '@/components/gl-tag'
import GlSearchBox from '@/components/gl-search-box'
import GlLoader from '@/components/gl-loader'
import GlIcon from '@/components/gl-icon'
import GlMenuItem from '@/components/gl-menu-item'
import GlConfirmModal from '@/components/gl-confirm-modal'
import GlSelect from '@/components/gl-select'
import GlInput from '@/components/gl-input.vue'

// Utils
import { capitalizeFirstLetter } from '@/utils/text-formatter'
import { getFirstRouteName } from '@/utils/get-route-name'

// Libs
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'

//Vuex
import { mapActions } from 'vuex'

export default {
  components: {
    ValidationObserver,
    vSelect,
    GlTag,
    GlSearchBox,
    GlLoader,
    GlIcon,
    GlMenuItem,
    GlConfirmModal,
    GlSelect,
    GlInput,
  },
  props: {
    typesList: {
      type: Array,
      default: () => [],
    },
    tagsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchField: '',
      loading: false,
      loadingDeleteModal: false,
      pagesOptions: [5, 10, 20, 50, 100],
      currentPage: 1,
      perPage: 50,
      totalPages: 1,
      total: 1,
      entityDataList: [],
      selected: {},
      selectedDelete: {},
      showConfirmDeleteModal: false,
      showFilter: false,
      filters: {
        type: {
          value: [],
          items: [],
        },
        tags: {
          value: [],
          items: [],
        },
        score: {
          from: null,
          to: null,
        },
      },
    }
  },
  computed: {
    showDeleteInTable() {
      return this.getFirstRouteName(this.$route) === 'entity-management'
    },
    showFilerSign() {
      return (
        this.filters.type.value.length ||
        this.filters.tags.value.length ||
        this.filters.score.from > 0 ||
        (this.filters.score.to > 0 && this.filters.score.to != 100)
      )
    },
  },
  watch: {
    searchField: {
      handler() {
        this.currentPage = 1
        this.searchDebounce()
      },
    },
    '$route.matched': {
      handler(e) {
        if (
          (e && e.length <= 1) ||
          this.$route.name === 'create-entity-management'
        ) {
          this.selected = {}
        }
      },
    },
    $route: {
      handler(val) {
        if (
          val.name === 'entity-explorer' ||
          val.name === 'entity-management'
        ) {
          this.searchField = ''
          this.currentPage = 1
          this.clearAllFilters()
          this.loadData()
        }
        if (val.name === 'view-entity-management') {
          this.loadData()
        }
      },
      immediate: true,
    },
    typesList: {
      handler(val) {
        this.filters.type.items = val
      },
    },
    tagsList: {
      handler(val) {
        this.filters.tags.items = val
      },
    },
  },
  mounted() {
    this.loadData()
  },
  created() {
    this.perPage = localStorage.getItem('entity-list-per-page') || this.perPage
    localStorage.setItem('entity-list-per-page', this.perPage)
  },

  methods: {
    capitalizeFirstLetter,
    getFirstRouteName,
    ...mapActions({
      getEntityList: 'entity/getEntityList',
      deleteEntity: 'entity/deleteEntity',
    }),
    async loadData() {
      const sendParams = this.formattedSendData()
      this.loading = true
      await this.getEntityList(sendParams)
        .then(({ items, count }) => {
          this.entityDataList = items
          this.total = count
          this.totalPages = Math.ceil(this.total / this.perPage)
          this.setFilterItems()
          this.selected =
            this.entityDataList.find(
              el => el.id === this.$route.params.entityId,
            ) || {}
        })
        .catch(e => {
          this.$toasted.global.error({ message: e })
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickDelete(el) {
      this.selectedDelete = el
      this.showConfirmDeleteModal = true
    },
    async deleteEntityById({ id }) {
      this.loadingDeleteModal = true
      this.deleteEntity({ id }).then(() => {
        if (
          this.entityDataList.length % this.perPage === 1 &&
          this.currentPage > 1
        ) {
          this.currentPage = this.currentPage - 1
        }
        this.loadingDeleteModal = false
        this.showConfirmDeleteModal = false
        this.$toasted.global.success({
          message: `${this.selectedDelete.name.value} was deleted`,
        })
        this.loadData()
        this.$router.push({
          name: 'entity-management',
        })
      })
    },

    searchDebounce: _.debounce(async function () {
      this.loadData()
    }, 1000),
    formattedSendData() {
      this.perPage =
        localStorage.getItem('entity-list-per-page') || this.perPage

      let score = undefined
      if (this.filters.score.from || this.filters.score.to) {
        score = [this.filters.score.from, this.filters.score.to]
      }

      return {
        sortField: this.sortField || undefined,
        $limit: this.perPage,
        searchAutocomplete: this.searchField,
        $skip: (this.currentPage - 1) * this.perPage,
        sortOrder: this.sortOrder || undefined,
        filterTypeId:
          (this.filters.type.value &&
            this.filters.type.value.length &&
            this.filters.type.value.map(e => e._id)) ||
          undefined,
        filterTagIds:
          (this.filters.tags.value &&
            this.filters.tags.value.length &&
            this.filters.tags.value.map(e => e._id)) ||
          undefined,
        score,
      }
    },
    setFilterItems() {
      this.filters.type.items = this.typesList
      this.filters.tags.items = this.tagsList
    },
    clearAllFilters() {
      this.filters.type.value = []
      this.filters.tags.value = []
      this.filters.score.from = null
      this.filters.score.to = null
      this.currentPage = 1
      this.loadData()
    },
    async applyFilter() {
      const isValid = await this.$refs.entityListFilter.validate()
      if (!isValid) return
      this.currentPage = 1
      this.loadData()
      this.showFilter = false
    },
    async toggleFilter() {
      const isValid = await this.$refs.entityListFilter.validate()
      if (!isValid) return
      this.showFilter = !this.showFilter
    },
    updateSelected(event) {
      if (
        this.$route.name === 'entity-explorer' ||
        this.getFirstRouteName(this.$route) === 'entity-explorer'
      ) {
        this.$router.push({
          name: 'view-entity-explorer',
          params: { entityId: event.id },
        })
      }
      if (
        this.$route.name === 'entity-management' ||
        this.getFirstRouteName(this.$route) === 'entity-management'
      ) {
        this.$router.push({
          name: 'view-entity-management',
          params: { entityId: event.id },
        })
        this.$nextTick(() => {
          this.selected =
            this.entityDataList.find(
              el => el.id === this.$route.params.entityId,
            ) || {}
        })
      }
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
    clearField() {
      this.searchField = ''
      this.$refs.entityName.resetError()
    },
    countChange() {
      this.currentPage = 1
      localStorage.setItem('entity-list-per-page', this.perPage)
      this.loadData()
    },
  },
}
</script>

<style>
.entity-table {
  flex: 1 0 auto;
}
.entity-table-wrapper {
  background: var(--white);
  padding: 0;
  padding-bottom: 16px;
}

.entity-table .o-table__wrapper {
  height: calc(100svh - 215px);
}
.entity-table .o-table__wrapper::-webkit-scrollbar {
  width: 8px;
}

.entity-table-wrapper .o-table {
  border: none;
}

.entity-table-wrapper .o-table tbody tr:hover {
  background: #f3f3f3;
}
.entity-table-wrapper .o-table tbody tr {
  cursor: pointer;
}

.entity-table-wrapper .stat-pagination .gl-menu-item svg{
  outline: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
}

.entity-list-filter-wrapper {
  position: absolute;
  top: 0px;
  right: -300px;
  background: var(--white);
  border-radius: 3px;
  box-shadow: 0px 0px 8px 0px rgba(110, 110, 110, 0.25);
  width: 300px;
  z-index: 2;
}

.filter-filled-sign::before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  height: 8px;
  width: 8px;
  background-color: var(--scarlet-red);
  border-radius: 50%;
}

.entity-table__filter-icon {
  display: flex;
  align-items: center;
}
.entity-table__filter-icon svg {
  position: relative;
  left: 4px;
}

.entity-table__to-bottom {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

@media (max-width: 1200px) {
  .entity-list-filter-wrapper {
    right: 48px;
  }
}
</style>
